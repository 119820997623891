<template>
  <div class="container">
    <div class="top1">
      <img src="../assets/images/navi_back.png" alt="" class="back" @click="back">
    </div>
    <div style="align-items: center;overflow-y: scroll;display: flex;flex-direction: column;width: 100vw;">


      <div v-if="loaded">
        <div class="history-item" v-for="(item, index) in list" :key="index" @click="goPage(item)">
          <img :src="item.avatar" alt="" class="avatar">

          <div class="expand">
            <span class="name">{{ item.nickname }}</span>
            <span class="time">{{ formateTime(item.time) }}</span>
          </div>

          <span class="icon-right" style="font-size:0.2rem;color: #74747A;"></span>
        </div>
        <div v-if="list.length == 0">
          <img class="no-icon" src="../assets/images/history.png" alt="">
          <div class="no-result">你还没有访问过其他人的主页</div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  props: {
    msg: String
  },
  data() {
    return {
      list: [],
      loaded: false
    }
  },
  mounted() {
    var _this = this;
    this.$get(this.$api.history).then(data => {
      _this.list = data.data
      _this.loaded = true
    })
  },
  methods: {
    back(){
      this.$router.push('/').catch(err=>{})
    },
    goPage(item) {
      this.$router.push({ path: '/index/'+item.url  }).catch(err=>{})
    },
    formateTime(t) {
      var date = new Date(t)
      var minutes = parseInt((new Date().getTime() - t) / 60000)
      if (minutes < 1)
        return '刚刚'
      else if (minutes <= 59)
        return minutes + '分钟前'
      else if (minutes <= 60 * 24 - 1) {
        return parseInt(minutes / 60) + '小时前';
      } else if (minutes <= 60 * 24 * 3 - 1) {
        return parseInt(minutes / (60 * 24)) + '天前';
      }
      return date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.top1{
  height: 0.48rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;

  padding-left: 0.16rem;
  // padding-left: 0.24rem;
  .back{
    width: 0.24rem;
    height: 0.24rem;
    padding: 0.06rem;
  }
}
.no-icon {
  width: 0.96rem;
  height: 0.96rem;
  margin-bottom: 0.16rem;
  margin-top: 2rem;
}

.no-result {
  color: #444447FF;
  font-size: 0.16rem;
}

.history-item {
  width: 3.43rem;
  height: 0.84rem;
  border-radius: 0.24rem;
  background-color: #262626FF;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-left: 0.2rem;
  padding-right: 0.12rem;
  margin-top: 0.08rem;
  border: none;
  overflow: hidden;

  .avatar {
    width: 0.48rem;
    height: 0.48rem;
    border-radius: 0.24rem;
    margin-right: 0.12rem;
    object-fit: cover;
  }
}

// a:hover {
//   transform: scale(0.95);
// }

.history-item:active {
  transform: scale(0.95);
}

.name {
  color: #FFFFFFFF;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 0.21rem;
}

.time {
  font-size: 0.11rem;
  color: #74747AFF;
  line-height: 0.16rem;
}

.expand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>